import React from 'react';
import cx from 'classnames';
import getDomain from '../services/getDomain';
import * as Meta from '@elevio/kb-kit/lib/components/meta';
import * as Category from '@elevio/kb-kit/lib/components/category';
import * as Article from '@elevio/kb-kit/lib/components/article';
import useScreenWidth from "../services/useScreenWidth";
import useReplaceVariables from '../hooks/useReplaceVariables';
function Breadcrumb({ className, crumbs, noLinks }) {
    const isSmallScreen = useScreenWidth('(max-width: 719px)');
    return (<div className={cx('breadcrumbs', className)}>
      <a className="breadcrumbs-link" href={getDomain()} target="_parent">Home</a>
      <svg className="chevron-right__icon" role="img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M4.8 2.4 7.2 0l12 12-12 12-2.4-2.4 9.6-9.6-9.6-9.6Z"></path></svg>
      {crumbs.map((crumb, index) => (((!isSmallScreen || (isSmallScreen && index !== crumbs.length - 1)) && (<React.Fragment key={index}>
            <Crumb crumb={crumb} className="breadcrumbs-link"/>
            {((!isSmallScreen && index < crumbs.length - 1) || (isSmallScreen && index < crumbs.length - 2)) && (<svg className="chevron-right__icon" role="img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M4.8 2.4 7.2 0l12 12-12 12-2.4-2.4 9.6-9.6-9.6-9.6Z"></path></svg>)}
          </React.Fragment>))))}
    </div>);
}
function Crumb({ crumb, className }) {
    if (crumb.linkType === 'article') {
        return (<Article.Link articleId={crumb.linkId} className={className}>
        {useReplaceVariables(crumb.title)}
      </Article.Link>);
    }
    if (crumb.linkType === 'category') {
        return (<Category.Link categoryId={crumb.linkId} className={className}>
        {useReplaceVariables(crumb.title)}
      </Category.Link>);
    }
    return <Meta.HomeLink className={className}>{crumb.title}</Meta.HomeLink>;
}
export default Breadcrumb;
