import React, { useEffect } from 'react';
import Chat from '../components/Chat';
import CallToAction from '../components/CallToAction';
import extractMessages from '../services/extractMessages';
import DgChat from '../components/DgChat';
import createDOMPurify from 'dompurify';
import useReplaceVariables from '../hooks/useReplaceVariables';
import useReplaceForCountrySection from '../hooks/useReplaceForCountrySection';
import useReplaceLinks from '../hooks/useReplaceLinks';
import useReplaceImages from '../hooks/useReplaceImages';
import { GridContainer } from '../components/layouts/Main';
import { updateHeight } from '../services/postHeightToParent';
const CustomArticleBody = ({ htmlString }) => {
    const DOMPurify = createDOMPurify(window);
    DOMPurify.setConfig({
        FORBID_TAGS: ['style'],
        ADD_TAGS: ['iframe'],
        ADD_ATTR: ['target'],
    });
    htmlString = useReplaceVariables(htmlString);
    htmlString = useReplaceImages(htmlString);
    htmlString = useReplaceLinks(htmlString);
    htmlString = useReplaceForCountrySection(htmlString);
    //   htmlString = htmlString + '{{chat::displaytext::themessage}}'
    //   htmlString = htmlString + '{{chat::displaytext}}'
    //   htmlString = htmlString + '{{dgchat::dgchatlink::dgchatmessage}}'
    //   htmlString = htmlString + '{{dgchat::dgchatlink}}'
    //   htmlString = htmlString + '{{calltoaction::maintitle::subtitle::https://www.elev.io}}'
    //   htmlString = htmlString + '{{calltoaction::maintitle::bla/index.php?myaccount=asdf&asdfas=sdfasd}}'
    const parts = extractMessages(htmlString);
    let gridCollection = [];
    useEffect(() => {
        updateHeight();
    });
    return (<div>
      {parts.map((part, index) => {
            if (typeof part === 'string') {
                return (<div key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(part) }}/>);
            }
            else {
                if (part.type === 'chat') {
                    return <Chat key={index} text={part.data.text} msg={part.data.msg}/>;
                }
                else if (part.type === 'calltoaction') {
                    if (index !== 0) {
                        let previouspart = parts[index - 1];
                        if (typeof previouspart === 'string' || !(parts[index + 1] instanceof Object) || ('type' in previouspart && previouspart.type !== 'calltoaction')) {
                            gridCollection.push(part);
                        }
                    }
                    let nextpart = parts[index + 1];
                    if (typeof nextpart === 'string' || !(nextpart instanceof Object) || ('type' in nextpart && nextpart.type !== 'calltoaction')) {
                        if (nextpart === '</p><p>' || nextpart === '<br>') {
                            const nextnextpart = parts[index + 2];
                            if (nextnextpart instanceof Object && 'type' in nextnextpart && nextnextpart.type === 'calltoaction') {
                                return null;
                            }
                        }
                        const gridComponents = gridCollection.map((gridpart, gridindex) => {
                            const { title, subtitle, link } = gridpart.data;
                            return [
                                <CallToAction key={gridindex} title={title} subtitle={subtitle} link={link}/>
                            ];
                        });
                        {
                            gridCollection = [];
                        }
                        return (<GridContainer key={index}>
                  {gridComponents}
                </GridContainer>);
                    }
                    else {
                        return null;
                    }
                }
                else if (part.type === 'dgchat') {
                    return <DgChat key={index} text={part.data.text} msg={part.data.msg}/>;
                }
                else {
                    return null;
                }
            }
        })}
    </div>);
};
export default CustomArticleBody;
