import React from 'react';
import { useArticleFragment } from '@elevio/kb-kit/lib/hooks';
import useReplacedVariables from '../../hooks/useReplaceVariables';
const HPArticleTitle = ({ articleId }) => {
    const article = useArticleFragment({ articleId });
    if (!article) {
        return null;
    }
    const updatedTitle = useReplacedVariables(article.title);
    return <span>{updatedTitle}</span>;
};
export default HPArticleTitle;
