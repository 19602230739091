import * as React from 'react';
import { useArticleFull } from '@elevio/kb-kit/lib/hooks';
import createDOMPurify from 'dompurify';
import SearchInput from '../components/SearchInput';
import { useTranslation } from '@elevio/kb-kit/lib/hooks';
import PageLayout from '../components/layouts/Page';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WithAsideLayout, { Aside, Main, MainHeader, MainBody, } from '../components/layouts/WithAside';
import CategoryList from '../components/CategoryList';
import ArticleList from '../components/ArticleList';
import Breadcrumb from '../components/PageBreadcrumbs';
import ArticleFeedback from '../components/ArticleFeedback';
import useReplaceVariables from '../hooks/useReplaceVariables';
import CustomArticleBody from '../components/CustomArticleBody';
function Page() {
    const { t } = useTranslation();
    const articlefromhook = useArticleFull();
    if (!articlefromhook)
        return null;
    const DOMPurify = createDOMPurify(window);
    DOMPurify.setConfig({
        FORBID_TAGS: ['style'],
        ADD_TAGS: ['iframe'],
        ADD_ATTR: ['target'],
    });
    return (<PageLayout header={<Header />} footer={<Footer />}>
      {/* <Helmet>
          <meta property="og:url" content="test-article" />
        </Helmet> */}
      <Breadcrumb className="breadcrumbs-header"/>
      <WithAsideLayout>
        <Main>
          <MainHeader>
            <h1 className="main-title" data-testid="article-title">
              {useReplaceVariables(articlefromhook.title)}
            </h1>
          </MainHeader>

          <MainBody>
            <CustomArticleBody htmlString={articlefromhook.body}/>
          </MainBody>
          <ArticleFeedback />
        </Main>

        <Aside>
          <SearchInput className="sidebar-search" placeholder={t('search.shortplaceholder', 'Search...')} data-testid="article-search-input"/>
          <CategoryList />
          <ArticleList />
        </Aside>
      </WithAsideLayout>
    </PageLayout>);
}
export default Page;
