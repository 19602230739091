import * as React from 'react';
import { useTranslation } from '@elevio/kb-kit/lib/hooks';
import * as Category from '@elevio/kb-kit/lib/components/category';
import ArticleListCards from '../components/ArticleListCards';
import { Replacer } from '../components/replacer';
import PageLayout from '../components/layouts/Page';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WithAsideLayout, { Aside, Main, MainHeader, MainBody, } from '../components/layouts/WithAside';
import SearchInput from '../components/SearchInput';
import Breadcrumb from '../components/PageBreadcrumbs';
import CategoryList from '../components/CategoryList';
import { useArticles } from '@elevio/kb-kit/lib/hooks';
import CategoryListWithArticleCards from '../components/CategoryListWithArticleCards';
import useReplaceVariables from '../hooks/useReplaceVariables';
import useCategoryIntro from '../hooks/userCategoryIntro';
import useCategoryActions from '../hooks/useActions';
import CategoryActions from '../components/category-actions/CategoryActions';
import { useParams } from 'react-router-dom';
function Page() {
    var _a, _b;
    const { t } = useTranslation();
    const { totalCount } = useArticles();
    const route = useParams();
    const categoryId = ((_b = (_a = route === null || route === void 0 ? void 0 : route['categoryId']) === null || _a === void 0 ? void 0 : _a.match(/\d+/)) === null || _b === void 0 ? void 0 : _b[0]) || '';
    const intro = useCategoryIntro(categoryId);
    const actions = useCategoryActions(categoryId);
    return (<PageLayout header={<Header />} footer={<Footer />}>
      {/* <Helmet>
          <meta property="og:url" content="test-caregory" />
        </Helmet> */}
      <Breadcrumb />
      <WithAsideLayout>
        <Main>
          <MainHeader>
            <h1 className="main-title" data-testid="category-title">
              <Replacer>
                <Category.Title />
              </Replacer>
            </h1>
          </MainHeader>
          {intro !== undefined && intro !== null && (<div className="category-intro">{useReplaceVariables(intro)}</div>)}
          {(actions === null || actions === void 0 ? void 0 : actions.length) > 0 && <CategoryActions actions={actions}/>}
          <MainBody data-testid="category-body">
            {totalCount === 0 && (<CategoryListWithArticleCards showTitle={true}/>)}
            {totalCount > 0 && <ArticleListCards showTitle={false}/>}
          </MainBody>
        </Main>

        <Aside>
          <SearchInput className="sidebar-search" placeholder={t('search.shortplaceholder', 'Search...')} data-testid="category-search-input"/>
          {totalCount > 0 && <CategoryList />}
        </Aside>
      </WithAsideLayout>
    </PageLayout>);
}
export default Page;
