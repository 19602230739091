function getDomain() {
    const pathArr = window.location.pathname.split('/');
    const lang = pathArr[1];
    switch (lang) {
        case 'nl':
            return 'https://www.drukzo.nl';
        case 'de':
            return 'https://www.helloprint.de';
        case 'fr':
            return 'https://www.helloprint.fr';
        case 'fr-be':
            return 'https://fr.helloprint.be';
        case 'es':
            return 'https://www.helloprint.es';
        case 'it':
            return 'https://www.helloprint.it';
        case 'nl-be':
            return 'https://www.drukzo.be';
        case 'at':
            return 'https://www.helloprint.com/at';
        case 'dk':
            return 'https://www.helloprint.com/dk';
        case 'se':
            return 'https://www.helloprint.com/se';
        case 'pt':
            return 'https://www.helloprint.com/se';
        default:
            return 'https://www.helloprint.co.uk';
    }
}
export default getDomain;
