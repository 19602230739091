import React from 'react';
import HPArticleTitle from './HpArticleTitle';
import useReplaceVariables from '../../hooks/useReplaceVariables';
import { useArticleFragment, useIsArticleActive } from '@elevio/kb-kit/lib/hooks';
import RouterLink from '@elevio/kb-kit/lib/componentsInternal/RouterLink';
import { getArticlePath } from '@elevio/kb-kit/lib/utils/routes';
const HPArticleLink = ({ children, articleId, slug, ...props }) => {
    const fragment = useArticleFragment({ articleId });
    const isActive = useIsArticleActive({ articleId: articleId || (fragment === null || fragment === void 0 ? void 0 : fragment.id) });
    let path = null;
    if (fragment) {
        path = fragment.path;
    }
    else if (slug) {
        path = getArticlePath({ slug });
    }
    else if (articleId) {
        path = getArticlePath({ slug: articleId });
    }
    if (!path)
        return null;
    const replacedPath = useReplaceVariables(path);
    const modifiedChildren = React.Children.map(children, (child) => {
        if (child.type.displayName === 'Article.Title') {
            return <HPArticleTitle articleId={articleId}/>;
        }
        return child;
    });
    return (<RouterLink to={replacedPath} isActive={() => isActive} data-article-id={articleId || (fragment === null || fragment === void 0 ? void 0 : fragment.id)} {...props}>
      {modifiedChildren}
    </RouterLink>);
};
export default HPArticleLink;
