import * as React from 'react';
import { useTranslation } from '@elevio/kb-kit/lib/hooks';
import * as Categories from '@elevio/kb-kit/lib/components/categories';
import PageLayout from '../components/layouts/Page';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { MainSection, GridContainer, GridItem, } from '../components/layouts/Main';
import CategoryCard from '../components/CategoryCard/CategoryCard';
import Banner from '../components/Banner/Banner';
const HomePage = () => {
    const { t } = useTranslation();
    const parentUrl = new URLSearchParams(window.location.search).get('parentUrl') || '';
    return (<PageLayout header={<Header />} footer={<Footer />}>
      <MainSection>
        <Banner />
        <h2 className="sub-title">{t('home.titleCategorySection', 'Finding answers starts here')}</h2>
        <GridContainer className='grid-3'>
          <Categories.Loop>
            <GridItem>
              <CategoryCard data-testid="category-card"/>
            </GridItem>
          </Categories.Loop>
        </GridContainer>
      </MainSection>
    </PageLayout>);
};
export default HomePage;
