import React from 'react';
import cx from 'classnames';
import Alert from '../Alert';
import LoadingBar from '../LoadingBar';
import useScrollToTop from '../../services/useScrollToTop';
import postPathToParent from '../../services/postPathToParent';
import { updateHeight } from '../../services/postHeightToParent';
import { Helmet } from '@elevio/kb-kit/lib/components/meta';
function PageLayout({ children, className, header, footer }) {
    // const [pageTitle, setPageTitle] = useState<string>('');
    // useEffect(() => {
    //   setPageTitle(document.title);
    // }, [])
    var _a;
    const parentUrl = (_a = new URLSearchParams(window.location.search).get('parentUrl')) !== null && _a !== void 0 ? _a : '';
    const canonicalUrl = getCanonicalUrl(parentUrl);
    const pathExLang = window.location.pathname.replace(/\/[a-z]{2}/, '');
    setNewCanonical(canonicalUrl + pathExLang);
    setNewOgUrl(parentUrl + '/cs' + pathExLang);
    replaceAllRelativeLinks(parentUrl);
    useScrollToTop();
    postPathToParent();
    updateHeight();
    return (<main className={cx('page', className)}>
      <Helmet>
        {/* <link rel="canonical" href={canonicalUrl + pathExLang} /> */}
        {/* <meta property="og:url" content={canonicalUrl + pathExLang} /> */}
        {/* Add any other custom meta tags you need */}
      </Helmet>
      <LoadingBar />
      {header}
      <Alert />
      <div className="body">
        <div className="body-wrapper">{children}</div>
      </div>
      {footer}
    </main>);
}
export default PageLayout;
function replaceAllRelativeLinks(parentUrl) {
    // scan the full documents for any <a> tags with an href that starts with /,
    // then replace everything before the 2nd slash with the parentUrl + '/cs/' + the href
    const anchors = document.querySelectorAll('a');
    anchors.forEach((anchor) => {
        let href = anchor.getAttribute('href');
        if (href && href.startsWith('/')) {
            href = href.replace(/\/[a-z]{2}/, '');
            anchor.setAttribute('href', parentUrl + '/cs' + href);
        }
    });
}
function setNewCanonical(canonicalUrl) {
    var _a;
    const linkElement = document.querySelector('link[rel="canonical"]');
    if (linkElement) {
        (_a = linkElement === null || linkElement === void 0 ? void 0 : linkElement.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(linkElement);
    }
    const link = document.createElement('link');
    link.href = canonicalUrl;
    link.rel = 'canonical';
    document.getElementsByTagName('head')[0].appendChild(link);
}
function setNewOgUrl(ogUrl) {
    var _a;
    const metaElement = document.querySelector('meta[property="og:url"]');
    if (metaElement) {
        (_a = metaElement === null || metaElement === void 0 ? void 0 : metaElement.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(metaElement);
    }
    const meta = document.createElement('meta');
    meta.setAttribute('property', 'og:url');
    meta.setAttribute('content', ogUrl);
    document.getElementsByTagName('head')[0].appendChild(meta);
}
function getCanonicalUrl(parentUrl) {
    switch (true) {
        case parentUrl.includes('.co.uk'):
            return 'https://www.helloprint.co.uk/cs';
        case parentUrl.includes('.nl'):
            return 'https://www.drukzo.nl/cs';
        case parentUrl.includes('fr.'):
            return 'https://fr.helloprint.be/cs';
        case parentUrl.includes('.be'):
            return 'https://www.drukzo.be/cs';
        case parentUrl.includes('.fr'):
            return 'https://www.helloprint.fr/cs';
        case parentUrl.includes('.es'):
            return 'https://www.helloprint.es/cs';
        case parentUrl.includes('.it'):
            return 'https://www.helloprint.it/cs';
        case parentUrl.includes('.de'):
            return 'https://www.helloprint.de/cs';
        default:
            return 'https://www.helloprint.co.uk/cs';
    }
}
