import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
function postPathToParent() {
    const { pathname } = useLocation();
    const pathArr = pathname.split('/');
    const pathForPresta = pathname.replace('/' + pathArr[1], '/cs');
    useEffect(() => {
        window.parent.postMessage({ type: 'routeChange', path: pathForPresta, title: document.title }, '*');
    }, [pathForPresta]);
}
export default postPathToParent;
