import React from 'react';
import { useCategories } from '@elevio/kb-kit/lib/hooks';
import * as Category from '@elevio/kb-kit/lib/components/category';
import * as Categories from '@elevio/kb-kit/lib/components/categories';
import ArticleList from '../components/ArticleList';
import { Replacer } from './replacer';
function CategoryList({ className }) {
    const { totalCount } = useCategories();
    if (totalCount === 0)
        return null;
    return (<div className={className}>
      {/* <p>components/categoryList</p> */}
      {/* <h3>
          <Trans i18nKey="category.listTitle">Categories</Trans>
        </h3> */}
      <ul className="category-list">
        <Categories.Loop>
          <li className="category-list-item">
            <Category.Link className="category-list-link category-listtitle-sidebar">
              <h3><Replacer><Category.Title /></Replacer></h3>
            </Category.Link>

            <ArticleList showTitle={false} className="articlelist-sidebar"/>
          </li>
        </Categories.Loop>
      </ul>
    </div>);
}
export default CategoryList;
