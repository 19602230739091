import React from 'react';
import { useArticles } from '@elevio/kb-kit/lib/hooks';
import * as Article from '@elevio/kb-kit/lib/components/article';
import * as Articles from '@elevio/kb-kit/lib/components/articles';
import * as Category from '@elevio/kb-kit/lib/components/category';
import HPArticleTitle from './extended/HpArticleTitle';
import HPArticleLink from './extended/HPArticleLink';
function ArticleList({ className, showTitle = true }) {
    const { totalCount } = useArticles();
    if (totalCount === 0)
        return null;
    return (<div className={className}>
      {/* <p>components/ArticleList</p> */}
      {showTitle &&
            // <h3>
            //   <Trans i18nKey="article.relatedTitle">Related Articles</Trans>
            // </h3>
            <Category.Link className="category-list-link category-listtitle-sidebar">
          <h3 className="category-title" data-testid="category-title">
            <Category.Title />
          </h3>
        </Category.Link>}
      <ul className="article-list">
        <Articles.Loop>
          <>
            <Article.IsActive>
              <li className="article-list-item">
                <strong>
                  {/* <Replacer>
          <Article.Title />
        </Replacer> */}
                  <HPArticleTitle />
                </strong>
              </li>
            </Article.IsActive>
            <Article.IsNotActive>
              <li className="article-list-item">
                {/* <Article.Link className="article-list-link">
            <Article.Title />
        </Article.Link> */}
                <HPArticleLink className="article-list-link">
                  <HPArticleTitle />
                </HPArticleLink>
              </li>
            </Article.IsNotActive>
          </>
        </Articles.Loop>
      </ul>
    </div>);
}
export default ArticleList;
