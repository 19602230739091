import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from '@elevio/kb-kit/lib/App';
import config from './config';
import './css/main.css';
import getDomain from "./services/getDomain";
checkForFrame();
const target = document.getElementById('kb-target');
if (!target)
    throw new Error('Cant find target div');
ReactDOM.render(<App config={config}>
  </App>, target);
function checkForFrame() {
    if ((window !== window.parent || window.location.hostname.indexOf('localhost') > -1)
        && window.location.href.indexOf('?themeVersion=') === -1) {
        return;
    }
    const indexNr = getPosition(window.location.pathname, "/", 2);
    const framedUrl = getDomain() + '/cs' + window.location.pathname.substring(indexNr) + window.location.search + window.location.hash;
    return window.location.replace(framedUrl);
}
;
function getPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length;
}
