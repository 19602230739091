import { useEffect } from 'react';
function postHeightToParent() {
    useEffect(updateHeight);
}
export function updateHeight() {
    setTimeout(refresh, 500);
    // setTimeout(refresh, 500)
    refresh();
    function refresh() {
        var _a;
        const heightForPresta = (_a = document.getElementById('kb-target')) === null || _a === void 0 ? void 0 : _a.scrollHeight;
        window.parent.postMessage({ type: 'setFrameHeight', height: Math.min(heightForPresta || 790) }, '*');
    }
}
export default postHeightToParent;
