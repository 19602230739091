import React from 'react';
import createDOMPurify from 'dompurify';
const DgChat = ({ text, msg }) => {
    const DOMPurify = createDOMPurify(window);
    DOMPurify.setConfig({
        FORBID_TAGS: ['style'],
        ADD_TAGS: ['iframe'],
        ADD_ATTR: ['target'],
    });
    return (<div className="question openChat" onClick={() => { window.parent.postMessage({ type: 'digitalgenius', message: msg }, '*'); }}>
            <svg style={{ marginRight: '8px' }} width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 17C17 17 14.7935 17.024 13.25 14.75C12.0935 15.176 10.7855 15.5 9.5 15.5C3.5 15.5 0.5 11 0.5 8C0.5 5 3.5 0.5 9.5 0.5C15.5 0.5 18.5 5 18.5 8C18.5 11 17 12.5 17 12.5V17ZM15.5 14.939V11.867C15.5 11.867 17 10.82 17 8C17 5.315 14.1635 2 9.5 2C4.508 2 2 5.66 2 8C2 10.34 4.5005 14 9.5 14C10.571 14 11.741 13.7495 13.835 12.935C14.0735 13.22 14.5955 14.327 15.5 14.939ZM5.798 7.271C5.59768 7.27104 5.40534 7.1925 5.2623 7.05227C5.11926 6.91203 5.03693 6.72128 5.033 6.521C5.033 6.107 5.3735 5.771 5.798 5.771H13.235C13.4353 5.77096 13.6277 5.8495 13.7707 5.98973C13.9137 6.12997 13.9961 6.32072 14 6.521C14 6.935 13.6595 7.271 13.235 7.271H5.798ZM5.798 10.2695C5.59757 10.2699 5.405 10.1915 5.26188 10.0512C5.11875 9.91088 5.03654 9.7199 5.033 9.5195C5.033 9.107 5.3735 8.7695 5.798 8.7695L13.235 8.75C13.658 8.75 14 9.095 14 9.4985C14 9.9125 13.6595 10.2485 13.235 10.25L5.798 10.2695Z" fill="#007AE8"/>
            </svg>
            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}/>
        </div>);
};
export default DgChat;
