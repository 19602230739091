import React from 'react';
import { useCategories } from '@elevio/kb-kit/lib/hooks';
import * as Category from '@elevio/kb-kit/lib/components/category';
import * as Categories from '@elevio/kb-kit/lib/components/categories';
import ArticleListCards from '../components/ArticleListCards';
import { Replacer } from './replacer';
function CategoryListWithArticleCards({ className, showTitle = true }) {
    const { totalCount } = useCategories();
    // if (totalCount === 0) return null;
    return (<div className={className}>
      {/* <p>components/CategoryListWithArticleCards</p> */}
      {/* <h3>
          <Trans i18nKey="category.listTitle">Categories</Trans>
        </h3> */}
      <ul className="category-list">
        <Categories.Loop>
          <li className="category-list-item">
            {showTitle &&
            <Category.Link className="category-list-link category-listtitle-sidebar">
                <h3><Replacer><Category.Title /></Replacer></h3>
              </Category.Link>}

            <ArticleListCards />
          </li>
        </Categories.Loop>
      </ul>
    </div>);
}
export default CategoryListWithArticleCards;
