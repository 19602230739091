import React from 'react';
import CategoryAction from './CategoryAction';
import useReplaceVariables from '../../hooks/useReplaceVariables';
function CategoryActions({ actions }) {
    return (<>
      <div className="category-actions">
        {actions.map((action, index) => {
            return <CategoryAction key={index} {...action}/>;
        })}
      </div>
      {actions[0].titleafter !== undefined && <h2 className="category-actions-title-after">{useReplaceVariables(actions[0].titleafter)}</h2>}
    </>);
}
export default CategoryActions;
