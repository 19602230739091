import { useState, useEffect, useMemo } from 'react';
const fetchCategoryIntros = async () => {
    try {
        const url = 'https://sheet-to-api.herokuapp.com/api/v1/helpcenter-category-intro';
        const response = await fetch(url);
        const raw = await response.json();
        return raw;
    }
    catch (err) {
        console.error('Error fetching category intro:', err.message);
    }
};
export default function useCategoryIntro(categoryId) {
    const [categoryIntro, setCategoryIntro] = useState(null);
    useEffect(() => {
        (async () => {
            const fetchedCategoryIntros = await fetchCategoryIntros();
            setCategoryIntro(fetchedCategoryIntros || []);
        })();
    }, [categoryId]); // Add categoryId to the dependency array
    const q = useMemo(() => {
        var _a;
        const pathArr = window.location.pathname.split('/');
        const lang = pathArr[1] || 'en';
        return (((_a = categoryIntro === null || categoryIntro === void 0 ? void 0 : categoryIntro.find((categoryIntro) => categoryIntro['elevio-category-id'] === categoryId)) === null || _a === void 0 ? void 0 : _a[lang]) || '');
    }, [categoryIntro]);
    return q;
}
