import React from 'react';
import { useArticles } from '@elevio/kb-kit/lib/hooks';
import * as Article from '@elevio/kb-kit/lib/components/article';
import * as Articles from '@elevio/kb-kit/lib/components/articles';
import * as Category from '@elevio/kb-kit/lib/components/category';
import { Replacer } from './replacer';
import cx from 'classnames';
import { GridContainer, GridItem, } from '../components/layouts/Main';
function ArticleListCards({ className, showTitle = false }) {
    const { totalCount } = useArticles();
    if (totalCount === 0)
        return null;
    return (<div className={className}>
      {/* <p>components/ArticleListCards</p> */}
      {showTitle &&
            <Category.Link className="category-list-link category-listtitle-sidebar">
          <h3 className="category-title" data-testid="category-title">
            <Replacer>
              <Category.Title />
            </Replacer>
          </h3>
        </Category.Link>}
      <ul className="article-list">
        <GridContainer>
          <Articles.Loop>
            <GridItem>
              <Article.Link data-testid="article-link">
                <div className={cx('card', 'article-link-title')}>
                  <div>
                    <h3 className={cx('card-title', 'article-link-title')} style={{ left: '15px', right: '45px' }}>
                      <Replacer>
                        <Article.Title />
                      </Replacer>
                    </h3>
                    <p className="article-link-summary">
                      <Replacer>
                        <Article.Summary />
                      </Replacer>
                    </p>
                  </div>
                  <svg className='card-arrow' width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.041 1.966C0.901922 1.82364 0.824058 1.63252 0.824058 1.4335C0.824058 1.23448 0.901922 1.04336 1.041 0.901003C1.17944 0.761301 1.36748 0.681965 1.56415 0.680279C1.76082 0.678594 1.95019 0.754694 2.091 0.892003L8.181 7.018L2.0985 13.099C1.9569 13.2378 1.76653 13.3155 1.56825 13.3155C1.36997 13.3155 1.1796 13.2378 1.038 13.099C0.968081 13.029 0.912678 12.9458 0.874972 12.8544C0.837267 12.7629 0.818 12.6648 0.818278 12.5659C0.818557 12.4669 0.838376 12.369 0.876597 12.2777C0.914817 12.1865 0.970687 12.1036 1.041 12.034L6.069 7.0075L1.041 1.966Z" fill="#007AE8"/>
                  </svg>
                </div>
              </Article.Link>
            </GridItem>
          </Articles.Loop>
        </GridContainer>
      </ul>
    </div>);
}
export default ArticleListCards;
