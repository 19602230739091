import React from 'react';
import cx from 'classnames';
import { useCategory } from '@elevio/kb-kit/lib/hooks';
import * as Category from '@elevio/kb-kit/lib/components/category';
import { getCategoryIcon } from '../../services/CategoryIcons';
import useSubtitles from '../../hooks/useSubtitles';
import styles from './CategoryCard.module.css';
function CategoryCard({ categoryId, className }) {
    const category = useCategory({ categoryId });
    const subTitle = useSubtitles(category === null || category === void 0 ? void 0 : category.id);
    if (!category)
        return null;
    const categoryIcon = getCategoryIcon(category.id);
    return (<Category.Link className={cx(styles.card, className)} data-testid="category-link">
      <div className={styles.icon}>
        {categoryIcon && (<div dangerouslySetInnerHTML={{ __html: categoryIcon }}/>)}
      </div>
      <div className={styles.header}>
        <h2 className={styles.title}>{category.title}</h2>
        <div className={styles.subtitle}>{subTitle}</div>
      </div>
    </Category.Link>);
}
export default CategoryCard;
