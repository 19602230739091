import { useState, useEffect, useMemo } from 'react';
const fetchCategoryActions = async () => {
    try {
        const url = 'https://sheet-to-api.herokuapp.com/api/v1/helpcenter-actions';
        const response = await fetch(url);
        const raw = await response.json();
        return raw;
    }
    catch (err) {
        console.error('Error fetching category actions:', err.message);
    }
};
export default function useCategoryActions(categoryId) {
    const [categoryActions, setCategoryActions] = useState(null);
    useEffect(() => {
        (async () => {
            const fetchedCategoryActions = await fetchCategoryActions();
            setCategoryActions(fetchedCategoryActions || []);
        })();
    }, [categoryId]); // Add categoryId to the dependency array
    const q = useMemo(() => {
        const pathArr = window.location.pathname.split('/');
        const lang = pathArr[1] || 'en';
        const found = categoryActions === null || categoryActions === void 0 ? void 0 : categoryActions.filter((categoryActions) => categoryActions['elevio-category-id'] === categoryId);
        return found === null || found === void 0 ? void 0 : found.map((categoryActions) => {
            return {
                title: categoryActions[`title-${lang}`],
                subtitle: categoryActions[`subtitle-${lang}`],
                titleafter: categoryActions[`titleafter-${lang}`],
                link: categoryActions[`link`],
            };
        });
    }, [categoryActions]);
    return q;
}
