export default function useReplaceForCountrySelection(replacedArticleBody) {
    const parentUrl = new URLSearchParams(window.location.search).get('parentUrl');
    if (!parentUrl)
        return replacedArticleBody;
    let regexesToReplace = {
        'forNL': /{{forNL}}[\s\S]*?{{\/forNL}}/g,
        'forBENL': /{{forBENL}}[\s\S]*?{{\/forBENL}}/g,
        'forFR': /{{forFR}}[\s\S]*?{{\/forFR}}/g,
        'forBEFR': /{{forBEFR}}[\s\S]*?{{\/forBEFR}}/g,
    };
    if (parentUrl.includes('fr.')) {
        delete regexesToReplace.forBEFR;
        regexesToReplace['{{forBEFR}}'] = /{{forBEFR}}/g;
        regexesToReplace['{{/forBEFR}}'] = /{{\/forBEFR}}/g;
    }
    else if (parentUrl.includes('.be')) {
        delete regexesToReplace.forBENL;
        regexesToReplace['{{forBENL}}'] = /{{forBENL}}/g;
        regexesToReplace['{{/forBENL}}'] = /{{\/forBENL}}/g;
    }
    else if (parentUrl.includes('.nl') || parentUrl.includes('localhost')) {
        delete regexesToReplace.forNL;
        regexesToReplace['{{forNL}}'] = /{{forNL}}/g;
        regexesToReplace['{{/forNL}}'] = /{{\/forNL}}/g;
    }
    else if (parentUrl.includes('.fr')) {
        delete regexesToReplace.forFR;
        regexesToReplace['{{forFR}}'] = /{{forFR}}/g;
        regexesToReplace['{{/forFR}}'] = /{{\/forFR}}/g;
    }
    Object.keys(regexesToReplace).forEach((key) => {
        replacedArticleBody = replacedArticleBody.replace(regexesToReplace[key], '');
    });
    return replacedArticleBody;
}
