import { useState, useEffect, useMemo } from 'react';
import getDomain from '../services/getDomain';
let patternsPromise = null;
const fetchPatterns = async () => {
    if (patternsPromise) {
        return patternsPromise;
    }
    const parentUrl = new URLSearchParams(window.location.search).get('parentUrl') || '';
    patternsPromise = (async () => {
        try {
            const url = 'https://sheet-to-api.herokuapp.com/api/v1/help_center_search_replace?dim=search';
            const response = await fetch(url);
            const raw = await response.json();
            const data = Object.entries(raw).reduce((acc, [key, value]) => {
                let itemValue = value[0][parentUrl];
                if (!itemValue) {
                    const langSourceDomain = getDomain();
                    itemValue = value[0][langSourceDomain];
                }
                acc[key] = itemValue;
                acc[encodeURIComponent(key)] = itemValue;
                return acc;
            }, {});
            return data;
            // setPatterns(data)
        }
        catch (err) {
            console.error('Error fetching patterns:', err.message);
        }
    })();
    return patternsPromise;
};
export default function useReplaceVariables(text) {
    const [patterns, setPatterns] = useState(null);
    const parentUrl = new URLSearchParams(window.location.search).get('parentUrl') || '';
    useEffect(() => {
        (async () => {
            const fetchedPatterns = await fetchPatterns();
            setPatterns(fetchedPatterns);
        })();
    }, []);
    const replacedText = useMemo(() => {
        if (!patterns)
            return text;
        const replaced = Object.entries(patterns).reduce((acc, [key, value]) => {
            try {
                return acc.replace(new RegExp(key, 'g'), value);
            }
            catch (error) {
                return acc;
            }
        }, text);
        return updateLinks(replaced, parentUrl);
    }, [text, patterns, parentUrl]);
    return replacedText;
}
function updateLinks(html, parentUrl) {
    const locale = window.location.pathname.match(/\/([a-z]{2})\//);
    if (!locale) {
        return html;
    }
    const localePrefix = locale[0]; // Example: "/en/"
    const hrefRegex = new RegExp(`(href=["'])(${localePrefix.replace('/', '\\/')}[^"']+)`, 'g');
    return html.replace(hrefRegex, (match, p1, p2) => {
        const cleanedPath = p2.replace(localePrefix, '/');
        const updatedHref = new URL(cleanedPath, parentUrl).toString();
        return p1 + updatedHref;
    });
}
