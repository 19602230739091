import React from 'react';
import SearchInput from '../SearchInput';
import { useTranslation } from '@elevio/kb-kit/lib/hooks';
import { getAssetURL } from '@elevio/kb-kit/lib/utils/globals';
import styles from './Banner.module.css';
function Banner() {
    const { t } = useTranslation();
    const [welcome, highlight] = t('home.welcome', 'Customer Service').split(',');
    const handleOpenChat = () => {
        window.parent.postMessage({ type: 'dixa' }, '*');
    };
    const backgroundImage = getAssetURL('helpcenter-banner.jpg');
    return (<div className={styles.banner} style={{
            backgroundImage: `url(${backgroundImage})`
        }}>
      <div className={styles.bannerWrapper}>
        <h1 className={styles.title}>
          {t('home.bannerTitle', 'Customer Service')}
        </h1>
        <div className={styles.subtitle}>
          {welcome}
          <button role="button" className={styles.button} onClick={handleOpenChat}>
            {highlight}
          </button>
        </div>
        <SearchInput placeholder={t('search.placeholder', 'Search our help centre...')} data-testid="search-input" className={styles.searchbar}/>
      </div>
    </div>);
}
export default Banner;
