import React, { useState } from 'react';
import cx from 'classnames';
import { useLanguages } from '@elevio/kb-kit/lib/hooks';
function Header({ children, className, hideSearch }) {
    const [displaySearchBar, setDisplaySearchBar] = useState(false);
    const [displayMobileMenu, setDisplayMobileMenu] = useState(false);
    const { supportedLanguages, selectedLanguage, setCurrentLanguageId } = useLanguages();
    return (<header className={cx('header', className)} data-testid="header">
      <div className="header-wrapper">
        {children && (<div className="hero">
            <div className="hero-wrapper">{children}</div>
          </div>)}
      </div>
    </header>);
}
export default Header;
