import { useState, useEffect, useMemo } from 'react';
let responsePromise = null;
const fetchSubs = async () => {
    if (responsePromise) {
        return responsePromise;
    }
    responsePromise = (async () => {
        try {
            const url = 'https://sheet-to-api.herokuapp.com/api/v1/helpcenter-meta';
            const response = await fetch(url);
            const raw = await response.json();
            return raw;
        }
        catch (err) {
            console.error('Error fetching subtitles:', err.message);
        }
    })();
    return responsePromise;
};
export default function useSubtitles(categoryId) {
    const [subs, setSubs] = useState(null);
    useEffect(() => {
        (async () => {
            const fetchedPatterns = await fetchSubs();
            setSubs(fetchedPatterns);
        })();
    }, []);
    const sub = useMemo(() => {
        var _a;
        if (!subs)
            return null;
        const pathArr = window.location.pathname.split('/');
        const lang = pathArr[1] || 'en';
        return (_a = subs.find((sub) => sub['elevio-category-id'] === categoryId)) === null || _a === void 0 ? void 0 : _a[lang];
    }, [categoryId, subs]);
    return sub;
}
