export default function extractMessages(html) {
    const regex = /{{(.*?)::(.*?)(?:::(.*?))?(?:::(.*?))?}}/g;
    let parts = [];
    let lastIndex = 0;
    let match;
    while ((match = regex.exec(html)) !== null) {
        const type = match[1];
        const data = {};
        data[type === 'calltoaction' ? 'title' : 'text'] = match[2];
        if (type === 'calltoaction') {
            if (match[3] !== undefined) {
                data['subtitle'] = match[3];
            }
            if (match[4] !== undefined) {
                data['link'] = match[4];
            }
            else {
                data['link'] = match[3]; // Fallback to the link if subtitle is not provided
                if (data['subtitle'] === data['link']) {
                    data['subtitle'] = ''; // Remove the subtitle if it is not provided
                }
            }
        }
        else if (match[3] !== undefined) {
            data['msg'] = match[3];
        }
        const index = match.index;
        if (index > lastIndex) {
            parts.push(html.slice(lastIndex, index));
        }
        parts.push({ type, data });
        lastIndex = index + match[0].length;
    }
    if (lastIndex < html.length) {
        parts.push(html.slice(lastIndex));
    }
    return parts;
}
