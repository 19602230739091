export default function useReplaceLinks(replacedArticleBody) {
    return replacedArticleBody.replace(/<a [^>]*>/g, (link) => {
        if (link.includes('target="_blank"')) {
            return link.replace('<a ', '<a rel="noopener noreferrer" ');
        }
        if (link.includes('target="_top"') || link.includes('target="_self"')) {
            return link.replace('target="_top"', 'target="_parent"').replace('target="_self"', 'target="_parent"');
        }
        if (!link.includes('target=')) {
            return link.replace('<a ', '<a target="_parent" ');
        }
    });
}
