import { useState, useEffect } from 'react';
function useScreenWidth(query) {
    const [isMatch, setIsMatch] = useState(false);
    useEffect(() => {
        const updateMatch = () => {
            const match = window.matchMedia(query).matches;
            setIsMatch(match);
        };
        updateMatch();
        window.addEventListener('resize', updateMatch);
        return () => window.removeEventListener('resize', updateMatch);
    }, [query]);
    return isMatch;
}
export default useScreenWidth;
